import React from "react"
import styled from "styled-components"

import ImageSwiper from "../ImageSwiper"
import Section from "../Section"
import SectionHeading from "../SectionHeading"
import ContactButton from "./ContactButton"

// import iconCalendar from "../../images/icon-calendar.svg"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"

const Container = styled.div`
  margin: 50px 0;

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .text-container {
      .address {
        margin-bottom: 20px;
        
        h3 {
          font-size: 16px;
          margin-bottom: 10px;
          color: ${({ theme }) => theme.palette.scienceBlue};
        }

        p {
          margin: 0;
        }
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 70px 0;
    .content {
      flex-direction: row-reverse;
      gap: 50px;
      align-items: center;
      justify-content: space-between;

      .image-container {
        width: 20%;
      }

      .image-container,
      .text-container {
        flex: 1 1 0px; //flex-grow flex-shrink flex-basis
      }
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 100px 0;
    .content {
      gap: 150px;
    }
  }
`

const BookVist: React.FC = () => {
  const ImageDataQuery = graphql`
    query {
      allImageSharp(
        filter: { fixed: { originalName: { regex: "/book-visit/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(layout: CONSTRAINED, quality: 100, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `
  const imageQuery = useStaticQuery(ImageDataQuery)

  return (
    <Container>
      <Section>
        <div className="content">
          <div className="text-container">
            <SectionHeading heading="Have a look for yourself." />
            <p>
              You want to make sure this club is for you. We get it. Book a time
              that suits you to visit our pool and chat with our coaches in
              their natural environment.
            </p>
            <div className="address">
              <h3>Our Address</h3>
              <p>Die Hoërskool Menlopark</p>
              <p>Rosemary Rd Entrance</p>
              <p>Menlo Park</p>
              <p>Pretoria</p>
            </div>
            {/* <ContactButton text="Book a visit" icon={iconCalendar} /> */}
          </div>
          <div className="image-container">
            <ImageSwiper images={[getImage(imageQuery.allImageSharp.edges[0].node)]} />
          </div>
        </div>
      </Section>
    </Container>
  )
}

export default BookVist
