import React, { useCallback } from "react"
import styled from "styled-components"
import Icon from "../Icon"

const Container = styled.div<{ color: string }>`
  background-color: ${({ theme }) => theme.palette.concrete};
  border-radius: 10px;
  display: flex;
  width: max-content;
  align-items: center;
  gap: 20px;
  padding: 0 20px 0 0;
  transition: background-color 0.2s, padding 0.2s;

  div.icon-container {
    background-color: ${({ theme, color }) =>
      color == "blue" ? theme.palette.scienceBlue : theme.palette.deepSeaGreen};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    img {
      margin: 0;
    }
  }

  p {
    margin: 0;
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => `${theme.palette.mineShaft}20`};
    padding: 0 30px 0 0;
  }
`

type Props = {
  icon: any
  color?: "blue" | "green"
  text: string
  onClick: () => void
}

const ContactButton: React.FC<Props> = ({
  color = "blue",
  icon,
  text,
  onClick,
}) => {
  return (
    <Container color={color} onClick={onClick}>
      <div className="icon-container">
        <Icon src={icon} size={28} />
      </div>
      <p>{text}</p>
    </Container>
  )
}
export default ContactButton
