import React from "react"
import styled from "styled-components"

import Section from "../Section"
import Slice from "../Slice"

import { contactInfo, contactLinks } from "../../utils/constants"
import iconPhone from "../../images/social/icon-phone.svg"
import iconEmail from "../../images/social/icon-mail.svg"
import { StaticImage } from "gatsby-plugin-image"

const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.mineShaft};
  width: 100%;
  position: relative;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 80px 0 40px 0;
    gap: 50px;

    .title-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h1 {
        color: ${({ theme }) => theme.palette.white};
        font-size: 52px;
        line-height: 45px;
        margin: 0;

        span {
          color: ${({ theme }) => theme.palette.scienceBlue};
        }
      }

      p {
        color: ${({ theme }) => theme.palette.white};
        margin: 0;
      }
    }

    .image-container {
      width: 100%;

      img {
        border-radius: 10px;
        margin: 0;
      }

      div.contact-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 20px 0 0 0;

        > a {
          display: flex;
          gap: 5px;
          align-items: center;
          color: ${({ theme }) => theme.palette.white};
          text-decoration: none;

          p {
            margin: 0;
            white-space: nowrap;
          }
        }
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    .content {
      padding: 150px 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 150px;

      .title-container {
        h1 {
          font-size: 82px;
          line-height: 75px;
          margin: 0;
        }
      }

      .image-container {
        width: 20%;
      }

      .title-container,
      .image-container {
        flex: 1 1 0px;
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    .content {
      .image-container {
        div.contact-info {
          flex-direction: row;
          margin: 0;
        }
      }
    }
  }
`

const Header = () => {
  return (
    <Container>
      <Section>
        <div className="content">
          <div className="title-container">
            <h1>
              <span>Contact</span> us.
            </h1>
            <p>
              We are located in a well-populated neighbourhood in the centre of
              Pretoria. Menlopark High School provides us with a 50m pool to
              train in throughout the year.
              <br />
              <br />
              Please feel free to contact us by phone or email with any queries,
              or come visit us in-person.
            </p>
          </div>
          <div className="image-container">
            <StaticImage
              src="../../images/contact/img-hero.jpg"
              alt="Players Swim"
              placeholder="tracedSVG"
              loading="eager"
              layout="fullWidth"
              quality={100}
            />
            <div className="contact-info">
              <a href={contactLinks.whatsapp} target="_blank">
                <img src={iconPhone} />
                <p>{contactInfo.phone}</p>
              </a>
              <a href={contactLinks.email}>
                <img src={iconEmail} />
                <p>{contactInfo.email}</p>
              </a>
            </div>
          </div>
        </div>
      </Section>
      <Slice color="#ffffff" style={{ position: "absolute", bottom: "0" }} />
    </Container>
  )
}

export default Header
