import { useEffect, useState } from "react"
import { contactInfo } from "./constants"

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 992) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  })

  return isMobile
}

type FormContentType = {
  name: string
  email: string
  message: string
}

export const useSendMessage = (
  method: "email" | "whatsapp",
  formContent: FormContentType
): void => {
  const constructedMessage = `
    ${formContent.message}\n\n
    (Name: ${formContent.name})
    (Email: ${formContent.email})
  `

  switch (method) {
    case "email":
      window.open(
        `mailto:${contactInfo.email}?&subject=New%20Web%20Message&body=${constructedMessage}`
      )
      break
    case "whatsapp":
      window.open(
        `https://api.whatsapp.com/send/?phone=${contactInfo.formattedPhone}&text=${constructedMessage}&app_absent=0`
      )
      break
  }
}
