import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import Header from "../components/contact/Header"
import Slice from "../components/Slice"
import BookVist from "../components/contact/BookVisit"
import StartChatting from "../components/contact/StartChatting"

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <Header />
      <BookVist />
      <StartChatting />
      <Slice color="#222222" />
    </Layout>
  )
}

export default ContactPage
