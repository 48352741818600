import React, { useRef, useState } from "react"
import styled from "styled-components"

import Section from "../Section"
import SectionHeading from "../SectionHeading"
import ContactButton from "./ContactButton"
import Icon from "../Icon"

import iconPhone from "../../images/social/icon-phone.svg"
import iconMail from "../../images/social/icon-mail.svg"
import iconWhatsapp from "../../images/social/icon-whatsapp.svg"
import iconTime from "../../images/services/icon-time.svg"
import iconCalendarBlack from "../../images/icon-calendar-black.svg"

import { contactInfo, contactLinks } from "../../utils/constants"
import { useIsMobile, useSendMessage } from "../../utils/hooks"

const Container = styled.div`
  margin: 50px 0;

  .content {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .form-container {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      div.form-element {
        display: flex;
        flex-direction: column;

        textarea {
          resize: none;
          white-space: pre-line;
        }

        input,
        textarea {
          border: none;
          border-radius: 10px;
          background-color: ${({ theme }) => theme.palette.concrete};
          padding: 10px;

          :focus {
            border: none;
          }
        }
      }

      div.form-buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    .text-container {
      .business-hours {
        margin-bottom: 20px;

        h3 {
          font-size: 16px;
          margin-bottom: 10px;
          color: ${({ theme }) => theme.palette.scienceBlue};
        }

        div {
          display: flex;
          gap: 10px;
          align-items: center;

          p {
            margin: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 70px 0;
    .content {
      flex-direction: row;
      gap: 50px;
      align-items: center;
      justify-content: space-between;

      .form-container {
        width: 20%;

        div.form-buttons {
          flex-direction: row;
        }
      }

      .form-container,
      .text-container {
        flex: 1 1 0px; //flex-grow flex-shrink flex-basis
      }
    }

    div#call-button {
      display: none;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin: 100px 0;
    .content {
      gap: 150px;
    }
  }
`

const StartChatting: React.FC = () => {
  const handleSendMessage = (method: "email" | "whatsapp") => {
    const name = (document.getElementById("name") as HTMLInputElement).value
    const email = (document.getElementById("email") as HTMLInputElement).value
    const message = (document.getElementById("message") as HTMLInputElement)
      .value
    // const formattedMessage = message.split("\n").join(" ")

    useSendMessage(method, {
      name: name,
      email: email,
      message: message.split("\n").join(" "),
    })
  }

  return (
    <Container>
      <Section>
        <div className="content">
          <div className="text-container">
            <SectionHeading heading="Let's start chatting." />
            <p>
              Need to ask us something? Send a message remotely through Whatsapp
              or email, or give us a call for more urgent queries.
            </p>
            <div className="business-hours">
              <h3>Business Hours</h3>
              <div>
                <Icon src={iconTime} size={24} /> <p>08:00 - 17:00</p>
              </div>
              <div>
                <Icon src={iconCalendarBlack} size={24} />{" "}
                <p>Monday - Friday</p>
              </div>
            </div>
            <div id="call-button">
              <ContactButton
                text="Give us a call"
                icon={iconPhone}
                onClick={() => window.open(contactLinks.phone)}
              />
            </div>
          </div>
          <div className="form-container">
            <div className="form-element">
              <label htmlFor="name">Name and Surname *</label>
              <input id="name" name="name" type="text" required />
            </div>
            <div className="form-element">
              <label htmlFor="name">Email *</label>
              <input id="email" name="email" type="email" required />
            </div>
            <div className="form-element">
              <label htmlFor="name">Message *</label>
              <textarea id="message" name="message" rows={3} required />
            </div>
            <div className="form-buttons">
              <ContactButton
                text="Send with Email"
                icon={iconMail}
                onClick={() => handleSendMessage("email")}
              />
              <ContactButton
                text="Send with Whatsapp"
                icon={iconWhatsapp}
                color="green"
                onClick={() => handleSendMessage("whatsapp")}
              />
            </div>
          </div>
        </div>
      </Section>
    </Container>
  )
}

export default StartChatting
